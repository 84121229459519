.authorPhoto {
  width: 200px;
  height: 293px;
  /* background-image: url('/niamh.jpeg'); */
  background-size: cover;
  margin: 0 auto 20px;
}

.aboutPage {
  display: flex;
  justify-content: space-between;
  flex-direction: column-reverse;
}

@media only screen and (min-width: 750px) {
  .aboutPage {
    flex-direction: row;
  }

  .column {
    width: 50%;
  }
}

@media only screen and (min-width: 750px) {
  .authorPhoto {
    width: 300px;
    height: 439px;
  }
}

.copy {
  margin: 0;
  float: none;
}

.authorPhotoFloat {
  display: none;
}

@media only screen and (min-width: 750px) {
  .authorPhotoFloat {
    display: none;
  }

  .authorPhotoFlex {
    display: block;
  }
}

.writing-list {
  padding-inline-start: 5px;
  list-style: none;
}

.writing-list li {
  margin-bottom: 5px;
}

.italics {
  font-style: italic;
}
