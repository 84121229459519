.main {
  width: 80%;
  margin: 4em auto;
  max-width: 800px;
}

.footer {
  margin: 0 auto;
  width: fit-content;
}

.footer img {
  margin: 15px;
  width: 30px;
}

.name-header {
  text-decoration: none;
}

.heading {
  margin-bottom: 0;
}

.profile-photo {
  width: 100%;
  float: left;
  margin-right: 20px;
  margin-bottom: 20px;
  @media only screen and (min-width: 600px) {
    width: 50%;
  }
}

.mobile-substack {
  @media only screen and (min-width: 600px) {
    display: none;
  }
}

.desktop-substack {
  display: none;
  @media only screen and (min-width: 600px) {
    display: initial;
  }
}

.active {
  font-weight: bold;
  text-decoration: none;
  cursor: auto;
}

.nav-bar {
  margin-bottom: 1em;
}

.italics {
  font-style: italic;
}