@font-face {
  font-family: "Avenir";
  src: url("font/avenirltstd-light.otf")
}

body {
  margin: 0;
  /* font-family: 'Avenir', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif; */
  font-family: Georgia, 'Times New Roman', Times, serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

h1 {
  font-weight: normal;
  font-size: 44px;
}

a {
  color: black;
}